// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//
//

function check_radio_buttons() {
  if ($('#opt_out_request_is_authorized_agent_true').is(':checked')) {
    $("#authorized-agent-name-group").removeClass('d-none');
  } else if ($('#opt_out_request_is_authorized_agent_false').is(':checked')) {
    $("#authorized-agent-name-group").addClass('d-none');
  } else {
    $("#authorized-agent-name-group").addClass('d-none');
  }
}

$(document).ready(function() {
  check_radio_buttons();

  $("#authorized-agent-radio-group").click(function() {;
    check_radio_buttons();
  });
});
